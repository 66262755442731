import Service from "../Service";
 
const resource = "HmnWorkerTab/";

export default {

    list(PrsID, requestID) {
        return Service.post(resource + "list", {}, {
            params: { PrsID:PrsID, requestID: requestID },
        });
    },
};