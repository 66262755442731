<template>
    <div>
        <v-card>
            <s-toolbar label="Ficha de Trabajador" dark color="#8e8f91">
                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn 
                            v-on="on"
                            text
                            black 
                            @click="generateWorker()"
                            small
                        > 
                            <i class="far fa-file-pdf"></i>
                        </v-btn>
                    </template>
                    <span>Generar ficha de trabajador</span>
                </v-tooltip>
            </s-toolbar>
            <v-container>
                <v-row>
                    <v-col sm="3" md="4">
                        <s-toolbar-person label="Buscar Persona" v-model="PrsDocumentNumber" noCamera noEdit @returnPerson="returnPerson($event)"/>
                    </v-col>
                    <v-col>
                        <s-text v-model="NtpFullName" label="Apellidos y Nombres" readonly>
                        </s-text>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            item-key="AgtID"
                            dense
                            fixed-header
                            :items="itemsSearch"
                            :headers="headersSearch"
                            :items-per-page="10"
                            @click:row="rowClickSearch"
                            v-model="selectedSearch"
                            show-select
                        >
                            <template v-slot:[`item.AgtStatus`]="{ item }">
                                <v-chip 
                                    small 
                                    :color="item.AgtStatus == 1 ? 'info' : 'error'"
                                    >
                                    {{ item.AgtStatus == 1 ? 'Activo' : 'Inactivo' }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.WkrAdmonition`]="{ item }">
                                <v-chip 
                                    small 
                                    :color="item.WkrAdmonition != null ? 'error' : 'info'"
                                    >
                                    {{ item.WkrAdmonition != null ? 'Si' : 'No' }}
                                </v-chip>
                            </template>
                            <template v-slot:[`item.AgtBeginDate`]="{ item }">
                                {{ $fun.formatDateView(item.AgtBeginDate) }}
                            </template>
                            <template v-slot:[`item.AgtEndDate`]="{ item }">
                                {{ $fun.formatDateView(item.AgtEndDate) }}
                            </template>
                            
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <br>
        <v-dialog v-if="openPdfWorker" v-model="openPdfWorker" width="unset" persistent>
            <worker-tab
                :item="item"
                :itemSelect="itemSelect"
                @close="openPdfWorker = false">
            </worker-tab>
        </v-dialog>
    </div>
</template>

<script>

import SText from '../../../components/Utils/SText.vue';
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import _sContractService 	from '@/services/HumanResource/HmnContractService.js';
import _sWorkerTabService from '@/services/HumanResource/HmnWorkerTabService.js';
import WorkerPdfTab from './HmnPrintPdfWorkerTab.vue';
import WorkerTab from './HmnPrintWorkerTab.vue';

export default {
    components: {
        SText,
        sToolbarPerson,
        WorkerPdfTab,
        WorkerTab
    },
    data() {
        return {
            item: null,
            itemSelect: {},
            selectedSearch: [],
            NtpFullName: "",
            PrsDocumentNumber: "",
            openPdfWorker: false,
            filter: {
                AgcID: 0,
                IsAdmin: this.$fun.isAdmin() ? 1 : 0,
            },
            TypeLaborDocument: 1,
            headersSearch: [
                {text: "ID"									, value: "AgtID"				},
                {text: "N° Doc"								, value: "PrsDocumentNumber"	},
                {text: "Nombres y Apellidos Completos"		, value: "NtpFullName"			},
                {text: "Area"								, value: "AreName"				},
                {text: "Cargo"								, value: "PstName"				},
                {text: "Fecha de Incio de Contrato"			, value: "AgtBeginDate"			},
                {text: "Fecha de Finalización de Contrato"	, value: "AgtEndDate"		 	},
                {text: "Estado"								, value: "AgtStatus"	 		},
                {text: "Restricción"						, value: "WkrAdmonition"		},
						
            ],
            itemsSearch: [
            ],
            dateKeys : [
                "AmsBeginDate",
                "AmsEndDate",
                "NtpBirthDate",
                "SecCreate"
            ]
        };
    },
    methods: {
        async fetchContractList() {
            const response = await _sWorkerTabService.list(this.PrsID, this.$fun.getUserID());
            if (response.status === 200) {
                this.itemsSearch = response.data;
            }
        },
        async returnPerson(event) {
            if(event){
                this.NtpFullName = event.NtpFullName;
                this.PrsDocumentNumber = event.PrsDocumentNumber;
                this.NtpID = event.NtpID;
                this.PrsID = event.PrsID;
                this.WkrID = event.WkrID;
                console.log(event);
                
                await this.fetchContractList();
                
            }
        },
        rowClickSearch: function(item, row) {
            if (item !== undefined) {
                if(item == this.selectedSearch[0]){
                    this.selectedSearch = [];
                }else{
                    this.selectedSearch = [item];
                }
            }
        },
        formatDates(obj, dateKeys) {
            for (let key of dateKeys) {
                if (obj[key]) {
                    let [year, month, day] = obj[key].split('T')[0].split('-'); // Divide la fecha en partes
                    obj[key] = `${day}/${month}/${year}`; // Reorganiza en formato DD/MM/YYYY
                }
            }
        },
        async textSearch(text){
            this.filter.Search = text;
            //await this.fetchContractListEmit();
        },
        async generateWorker() {

            if(this.selectedSearch.length == 0) {
                this.$fun.alert("Debe seleccionar un contrato","warning");
                return;
            }

            //console.log(this.selectedSearch[0]);

            _sContractService.workerPerson(this.selectedSearch[0].WkrID,this.selectedSearch[0].AgtID, this.$fun.getUserID())
            .then((r) => {
                if (r.status == 200) {
                    
                    this.dataWork = r.data;
                    this.item 		= this.dataWork;
                    this.itemSelect = this.selectedSearch[0];
                    this.openPdfWorker = true;
                    console.log("itemSelect",this.itemSelect);
                }
            });

        }
    }
}
</script>