<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion de Constancia"
                dark
                :color="'#8e8f91'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
                <hr>
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="'FICHA_TRABAJADOR_' + this.itemSelect.NtpFullName + '_' + new Date().toLocaleDateString() + '.pdf'"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
                <section slot="pdf-content">
                    <div style="font-family: Arial, sans-serif; margin: 0; padding: 20;">
                        <div style="border: 1px solid #000; width: 730px; margin: 50px auto; padding: 0px;">
                            <!-- Encabezado con 3 secciones -->
                            <div style="display: flex; width: 100%; font-family: Arial, sans-serif; margin-bottom: 15px; border-bottom: 1px solid #000">
                                <!-- Sección Izquierda - Logo -->
                                <div style="flex: 1; display: flex; justify-content: center; align-items: center; border-right: 1px solid black; padding: 10px;">
                                    <img src="/static/logo_sp.png" alt="Logo" style="width: 140px; height: auto;">
                                </div>

                                <!-- Sección Central - Título -->
                                <div style="flex: 2; display: flex; justify-content: center; align-items: center; border-right: 1px solid black; padding: 10px; text-align: center;">
                                    <h2 style="margin: 0; font-size: 14px; font-weight: bold;">FICHA DE DATOS DEL PERSONAL</h2>
                                </div>

                                <!-- Sección Derecha - Información -->
                                <div style="flex: 1; font-size: 9px; padding: 10px; text-align: center;">
                                    <div style="margin-bottom: 2px;"><strong>CÓDIGO: RH-FR-002</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>VERSIÓN: 06</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Fecha de Creación: 29/09/2014</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Última Revisión: {{ this.ultimateRevision }}</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Páginas: 01 de 01</strong></div>
                                    <div style="margin-bottom: 2px;"><strong>Anexo: 00</strong></div>
                                </div>
                            </div>

                            <!-- Sección de Datos Personales -->
                            <div style="margin-bottom: 20px;">
                                
                                <!-- Título de Datos Personales -->
                                <div style="background-color: rgb(242 242 242); padding: 5px; text-align: center; font-weight: bold; margin-bottom: 15px; border-top: 1px solid #000; border-bottom: 1px solid #000">
                                    DATOS PERSONALES
                                </div>

                                <div style="padding: 0px 20px;">
                                    <!-- Contenido principal dividido en dos columnas -->
                                    <div style="display: flex;">
                                        <!-- Columna izquierda - Datos personales -->
                                        <div style="flex: 4; padding-right: 20px;">
                                        <!-- Apellidos y nombres - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 160px; font-weight: bold; font-size: 12px;">APELLIDOS Y NOMBRES</div>
                                                <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.NtpFullName }}</div>
                                            </div>
                                            
                                            <!-- DNI, Fecha de nacimiento y Edad - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 50px; font-weight: bold; font-size: 12px;">DNI</div>
                                                <div style="width: 150px; margin-right: 10px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">
                                                    <span style="display: flex; align-items: center; justify-content: center; height: 100%;">{{ this.itemSelect.PrsDocumentNumber }}</span>
                                                </div>
                                            </div>

                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 160px; font-weight: bold; font-size: 12px;">FECHA DE NACIMIENTO</div>
                                                <div style="width: 45px; height: 20px; border-top: 1px solid #000; border-left: 1px solid #000; border-bottom: 1px solid #000; display: inline-block; display: flex; align-items: center; justify-content: center;">{{ this.birthDay }}</div>
                                                <div style="width: 45px; height: 20px; border-top: 1px solid #000; border-left: 1px solid #000; border-bottom: 1px solid #000; display: inline-block; display: flex; align-items: center; justify-content: center;">{{ this.birthMonth }}</div>
                                                <div style="width: 45px; height: 20px; border: 1px solid #000; display: inline-block; margin-right: 40px; display: flex; align-items: center; justify-content: center;">{{ this.birthYear }}</div>
                                                <div style="width: 40px; font-weight: bold; font-size: 12px;">EDAD</div>
                                                <div style="width: 40px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.age }}</div>
                                            </div>
                                            
                                            <!-- Sexo -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 50px; font-weight: bold; font-size: 12px;">SEXO</div>
                                                <div style="display: flex; align-items: center; width: 110px;">
                                                    <div style="width: 50%; height: 20px; border-top: 1px solid #000; border-left: 1px solid #000; border-bottom: 1px solid #000; display: flex; align-items: center; justify-content: center; position: relative;">
                                                        <span v-if="this.itemSelect.TypeSexName === 'FEMENINO'" style="position: absolute; font-size: 18px; line-height: 12px; color: rgba(255, 0, 0, 0.5);">X</span>
                                                        <span>F</span>
                                                    </div>
                                                    <div style="width: 50%; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center; position: relative;">
                                                        <span v-if="this.itemSelect.TypeSexName === 'MASCULINO'" style="position: absolute; font-size: 18px; line-height: 12px; color: rgba(255, 0, 0, 0.5);">X</span>
                                                        <span>M</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 100px; font-weight: bold; font-size: 12px;">TELEFONO</div>
                                                <div style="width: 200px; margin-right: 10px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.NumberPhone }}</div>
                                            </div>
                                        </div>
                                        
                                        <!-- Columna derecha - Foto -->
                                        <div style="flex: 1;">
                                            <div style="width: 150px; height: 150px; display: flex; justify-content: center; align-items: center; margin-bottom: 10px; overflow: hidden;">
                                                <v-img :src="image ? image : '../../../public/static/User.png'" style="width: 100%; height: 100%; object-fit: cover;"/>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Correo electrónico - en línea -->
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 160px; font-weight: bold; font-size: 12px;">CORREO ELECTRONICO</div>
                                        <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.PemEmail }}</div>
                                    </div>
                                    
                                    <!-- Dirección - en línea -->
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 100px; font-weight: bold; font-size: 12px;">DIRECCION</div>
                                        <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.PadAddress }}</div>
                                    </div>
                                    
                                    <!-- Distrito y Zona - en línea -->
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 100px; font-weight: bold; font-size: 12px;">DISTRITO</div>
                                        <div style="width: 150px; margin-right: 60px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.DisName }}</div>
                                        <div style="width: 70px; font-weight: bold; font-size: 12px;">ZONA</div>
                                        <div style="width: 200px; margin-right: 10px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.ZoneText }}</div>
                                    </div>
                                    
                                    <!-- Estado Civil - en línea -->
                                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                        <div style="width: 100px; font-weight: bold; font-size: 12px;">ESTADO CIVIL</div>
                                        <div style="width: 150px; margin-right: 10px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.TypeCivilStatus }}</div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Sección de Datos Laborales -->
                            <div style="margin-bottom: 20px;">
                                <!-- Título de Datos Laborales -->
                                <div style="background-color: rgb(242 242 242); padding: 5px; text-align: center; font-weight: bold; margin-bottom: 15px; border-top: 1px solid #000; border-bottom: 1px solid #000">
                                    DATOS LABORALES
                                </div>

                                <div style="padding: 0px 20px;">
                                    <div style="display: flex;">
                                        <!-- Columna izquierda - Datos personales -->
                                        <div style="flex: 4; padding-right: 20px;">
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">PLANILLA</div>
                                                <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.TypePayrollName }}</div>
                                            </div>

                                            <!-- Área de trabajo - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">AREA DE TRABAJO</div>
                                                <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.AreName }}</div>
                                            </div>
                                            
                                            <!-- Cargo - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">CARGO</div>
                                                <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.PstName }}</div>
                                            </div>
                                            
                                            <!-- Fecha de ingreso - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">FECHA DE INGRESO</div>
                                                <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ $fun.formatDateView(this.itemSelect.AgtBeginDate) }}</div>
                                            </div>
                                            
                                            <!-- Sistema pensionario - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">SISTEMA PENSIONARIO</div>
                                                <div style="flex: 1; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.NameTypePension }}</div>
                                            </div>
                                            
                                            <!-- Entidad bancaria y Nro. de cuenta - en línea -->
                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">ENTIDAD BANCARIA</div>
                                                <div style="width: 200px; margin-right: 20px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.TypeEntityBanckName }}</div>
                                                
                                            </div>


                                            <div style="display: flex; align-items: center; margin-bottom: 10px;">
                                                <div style="width: 180px; font-weight: bold; font-size: 12px;">NRO. DE CUENTA</div>
                                                <div style="width: 200px; margin-right: 20px; height: 20px; border: 1px solid #000; display: flex; align-items: center; justify-content: center;">{{ this.itemSelect.BatAccountNumber }}</div>
                                            </div>
                                        </div>
                                        <div style="flex: 2;">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Pie de página -->
                            <div style="display: flex; margin: 30px">
                            
                                <!-- Espacio en blanco -->
                                <div style="flex: 3;"></div>
                                
                                <!-- Firma de RRHH -->
                                <div style="flex: 2; text-align: center; ">
                                    <div style="margin-top: 30px; border-top: 1px solid #000; padding-top: 5px; font-size: 12px; width: 200px; font-weight: bold;">
                                    VB° RECURSOS HUMANOS
                                    </div>
                                </div>
                                
                                <div style="flex: 1;"></div>
                            </div>
                        </div>
                    </div>
                </section>
                </vue-html2pdf>
        </v-card>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";
    import qrCode from "vue-qr-generator";
    import _sHelper from "@/services/HelperService";
    import _sGenParam from "../../../services/General/ParameterService";

    export default{ 
       
        props:{
            item: {
				type: Object,
				default: null,
			},
            itemSelect: {
				type: Object,
                default: null,
			},
        },
        components:{
            VueHtml2pdf,
            qrCode
        },
        data(){
            return{
                urlData: "",
                image: null,
                contractData: [],
                MovemtsRegister: [],
                pensionCE: [],
                HealthCE: [],
                BankAccountsCE: [],
                totalpages: 0,
                birthYear: null,
                birthMonth: null,
                birthDay: null,
                age: null,
                ultimateRevision: null
            }
        },
        methods:{ 
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				//console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				//console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				//console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
                console.log(`On Before PDF Generation`)
                    html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                        // const totalPages = pdf.internal.getNumberOfPages()
                        // for (let i = 1; i <= totalPages; i++) {
                        // 	pdf.setPage(i)
                        // 	pdf.setFontSize(10)
                        // 	pdf.setTextColor(150)
                        // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        // } 
                    }).save()
            },
            formatDate(dateString) {
                const [day, month, year] = dateString.split('/');
                const months = {
                '01': 'enero',
                '02': 'febrero',
                '03': 'marzo',
                '04': 'abril',
                '05': 'mayo',
                '06': 'junio',
                '07': 'julio',
                '08': 'agosto',
                '09': 'septiembre',
                '10': 'octubre',
                '11': 'noviembre',
                '12': 'diciembre'
                };
                return `${parseInt(day)} de ${months[month]} del ${year}`;
            },
            getImagePerson(document) {
                _sHelper.getPhotoWorkerBase64(document, this.$fun.getUserID()).then((rsp) => {
                if (rsp.status === 200) {
                    this.image = 'data:image/jpg;base64,' + rsp.data;  // Asignamos la cadena Base64 directamente
                    return this.image;
                } else {
                    console.error("No se pudo cargar la imagen");
                }
                }).catch((error) => {
                console.error("Error al obtener la imagen:", error);
                });

            },
            extractDateParts(item) {
                if (item) {
                const dateParts = item.split("T")[0].split("-");
                this.birthYear = parseInt(dateParts[0]); // "2000"
                this.birthMonth = parseInt(dateParts[1]); // "01"
                this.birthDay = parseInt(dateParts[2]); // "05"

                // Calcular edad
                const today = new Date();
                let age = today.getFullYear() - this.birthYear;

                // Verificar si aún no ha cumplido años este año
                const monthDiff = today.getMonth() + 1 - this.birthMonth;
                const dayDiff = today.getDate() - this.birthDay;
                if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                    age--;
                }

                this.age = age;
                }
            },
            loadParameters() {
                _sGenParam
                    .search({ PrmName: "RH-FR-002" }, this.$fun.getUserID())
                    .then((resp) => {
                        if (resp.status == 200) {
                            this.ultimateRevision = resp.data.PrmValue;
                        }
                    });
            }
        },
        mounted(){
            //console.log('itemSelect', this.itemSelect);
            this.extractDateParts(this.itemSelect.NtpBirthDate);
            //console.log('item', this.item);
            this.MovemtsRegister = this.item.AgreementCE.AgreementMovemtsCE;
            const AgtIDRegister = this.MovemtsRegister.reduce((acc, current) => {
            const agtID = current.AgtID;
            const amsID = current.AmsID;

            if (!acc[agtID] || amsID > acc[agtID].AmsID) {
                acc[agtID] = current;
            }

            return acc;
            }, {});

            this.contractData = Object.values(AgtIDRegister);
            this.pensionCE = this.item.PensionCE.filter(detail => detail.SecStatus === 1);
            this.HealthCE = this.item.HealthCE.filter(detail => detail.SecStatus === 1);
            this.BankAccountsCE = this.item.BankAccountsCE.filter(detail => detail.SecStatus === 1);
            this.getImagePerson(this.itemSelect.PrsDocumentNumber);

            this.loadParameters();
        }
    }
</script>