<template>
    <div class="d-flex align-center flex-column">
        <v-card>
            <s-toolbar
                label="Impresion de Constancia"
                dark
                :color="'#8e8f91'"
                close
                @close="$emit('close')"
                class="mb-6"
            ></s-toolbar>
            <v-card-title style="font-size: small;">
				<v-row>
					<v-col align="right">
						<v-btn 
							fab 	
							x-small
							color="info"
							@click="downloadPdf()"
						>	<v-icon medium>mdi-printer</v-icon>				
						</v-btn>
					</v-col>
				</v-row>	
			</v-card-title>
                <hr>
                <vue-html2pdf
                :show-layout="true"
                :float-layout="false"
                :enable-download="false"
                :preview-modal="false"
                :filename="'FICHA_TRABAJADOR_' + this.itemSelect.NtpFullName + '_' + new Date().toLocaleDateString() + '.pdf'"
                :paginate-elements-by-height="1200"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px" 
                :manual-pagination="true"
				:margin="1"
                @progress="onProgress($event)"
                @startPagination="startPagination()"
                @hasPaginated="hasPaginated()"
                @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
                <section slot="pdf-content">
                    <div style="font-family: Arial, sans-serif; margin: 20px; padding: 20px;">
                        <!-- Header Section -->
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
                            <div style="flex: 1;">
                                <img src="/static/logo_sp.png" alt="" style="width: 120px; height: auto;">
                                <h4 style="margin: 0;">SUNSHINE EXPORT S.A.C</h4>
                                <strong><p style="margin: 5px 0;">RUC: 20329725431</p></strong>
                            </div>
                            <div style="flex: 1; text-align: center;">
                                <h2 style="margin-bottom: 10px;">FICHA DE PERSONAL</h2>
                                <h3 style="margin: 0;">{{ this.itemSelect.NtpFullName }}</h3>
                            </div>
                            <div style="flex: 1; text-align: right;">
                                <!-- <p style="margin: 0;">Pág. 1/1</p> -->
                                <p style="margin: 0;">{{ new Date().toLocaleDateString() }}</p>
                                <p style="margin: 0;">{{ new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }) }}</p>
                            </div>
                        </div>

                        <hr style="border: 1px solid #000; margin: 20px 0;">

                        <!-- Datos Generales -->
                        <h3 style="margin-bottom: 15px;">I. DATOS GENERALES</h3>
                        <div style="display: flex; gap: 10px;">
                            <div style="flex: 3;">
                                <p style="margin: 5px 0;"><strong>Nacionalidad:</strong> {{ this.itemSelect.Nationality }}</p>
                                <p style="margin: 5px 0;"><strong>Tipo Doc:</strong> {{ this.itemSelect.DocNationality }}</p>
                                <p style="margin: 5px 0;"><strong>Género:</strong> {{ this.itemSelect.TypeSexName }}</p>
                                <p style="margin: 5px 0;"><strong>Teléfono:</strong> {{ this.itemSelect.NumberPhone }}</p>
                                <p style="margin: 5px 0;"><strong>Dirección:</strong> {{ this.itemSelect.PadAddress }}</p>
                                <p style="margin: 5px 0;"><strong>Referencia:</strong> {{ this.itemSelect.PadAddressReference }}</p>
                                <p style="margin: 5px 0;"><strong>Ubigeo:</strong> {{ this.itemSelect.DisName }} - {{ this.itemSelect.ProName }} - {{ this.itemSelect.DepName }}</p>
                            </div>
                            <div style="flex: 3;">
                                <p style="margin: 5px 0;"><strong>Nro Doc:</strong> {{ this.itemSelect.PrsDocumentNumber }}</p>
                                <p style="margin: 5px 0;"><strong>F. Nacimiento:</strong> {{ $fun.formatDateView(this.itemSelect.NtpBirthDate) }}</p>
                                <p style="margin: 5px 0;"><strong>Fecha Ingreso:</strong> {{ $fun.formatDateView(this.itemSelect.AgtBeginDate) }}</p>
                                <p style="margin: 5px 0;"><strong>Correo:</strong> {{ this.itemSelect.PemEmail }}</p>
                            </div>
                            <div style="flex: 1; display: flex; justify-content: space-between; align-items: center;">
                                <div style="height:150px; width:150px; border-radius:10px; overflow:hidden">
                                    <v-img :src="image ? image : '../../../public/static/User.png'" style="height:100%;width:100%" />
                                </div>
                            </div>
                        </div>

                        <hr style="border: 1px solid #000; margin: 20px 0;">

                        <!-- Datos de Contrato -->
                        <h3 style="margin-bottom: 15px;">II. Datos de Contrato</h3>
                        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
                            <tr style="background-color: #f2f2f2;">
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Tipo Contrato</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Inicio Contrato</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Fin Contrato</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Fecha Cese</th>
                            </tr>
                            <tr v-for="(detail, index) in contractData" :key="index">
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypeContractName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ $fun.formatDateView(detail.AmsBeginDate) }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ $fun.formatDateView(detail.AmsEndDate) }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ $fun.formatDateView(detail.AmsTerminationDate) }}</td>
                            </tr>
                        </table>

                        <!-- Área, Cargo y Tipo -->
                        <!-- <h3 style="margin-bottom: 15px;">III. Área, Cargo y Tipo del Trabajador</h3>
                        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
                            <tr style="background-color: #f2f2f2;">
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Área</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Cargo</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Tipo</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Inicio</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Fin</th>
                            </tr>
                            <tr v-for="(detail, index) in contractData" :key="index">
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.AreName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.PstName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypeOccupationalCategoryName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ $fun.formatDateView(detail.AmsBeginDate) }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ $fun.formatDateView(detail.AmsEndDate) }}</td>
                            </tr>
                        </table> -->

                        <!-- Régimen Pensión -->
                        <!-- <h3 style="margin-bottom: 15px;">IV. Régimen Pensión</h3>
                        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
                            <tr style="background-color: #f2f2f2;">
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Tipo Pensión</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Régimen Pension</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">CUSPP</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Fecha Ingreso</th>
                            </tr>
                            <tr v-for="(detail, index) in pensionCE" :key="index">
                                <td style="border: 1px solid #000; padding: 8px;">NINGUNO</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypePensionRegimenName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.CUSPP }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ $fun.formatDateView(detail.PsnBeginDate) }}</td>
                            </tr>
                        </table> -->

                        <!-- Régimen Salud -->
                        <!-- <h3 style="margin-bottom: 15px;">V. Régimen Salud</h3>
                        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
                            <tr style="background-color: #f2f2f2;">
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Sistema Salud</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Régimen Salud</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Compañia</th>
                            </tr>
                            <tr v-for="(detail, index) in HealthCE" :key="index">
                                <td style="border: 1px solid #000; padding: 8px;">NINGUNO</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypeHealthRegimenName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypeCompanyName }}</td>
                            </tr>
                        </table> -->

                        <!-- Cuenta Bancaria -->
                        <!-- <h3 style="margin-bottom: 15px;">VI. Cuenta Bancaria</h3>
                        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
                            <tr style="background-color: #f2f2f2;">
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Cuenta</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Moneda</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Nro Cuenta</th>
                                <th style="border: 1px solid #000; padding: 8px; text-align: left;">Entidad Bancaria</th>
                            </tr>
                            <tr v-for="(detail, index) in BankAccountsCE" :key="index">
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypePayName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypeMoneyName }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.BatAccountNumber }}</td>
                                <td style="border: 1px solid #000; padding: 8px;">{{ detail.TypeEntityBankName }}</td>
                            </tr>
                        </table> -->
                    </div>
                </section>
                </vue-html2pdf>
        </v-card>
    </div>
</template>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import _sReferral from "@/services/Logistics/LgsReferralGuideService.js";
    import qrCode from "vue-qr-generator";
    import _sHelper from "@/services/HelperService";

    export default{ 
       
        props:{
            item: {
				type: Object,
				default: null,
			},
            itemSelect: {
				type: Object,
                default: null,
			},
        },
        components:{
            VueHtml2pdf,
            qrCode
        },
        data(){
            return{
                urlData: "",
                image: null,
                contractData: [],
                MovemtsRegister: [],
                pensionCE: [],
                HealthCE: [],
                BankAccountsCE: [],
                totalpages: 0
            }
        },
        methods:{ 
            downloadPdf(){
                this.$refs.html2Pdf.generatePdf();
            },
            onProgress(progress) {
				this.progress = progress;
				console.log(`PDF generation progress: ${progress}%`)
			},
			startPagination() {
				console.log(`PDF has started pagination`)
			},

			hasPaginated () {
				console.log(`PDF has been paginated`)
			},

			beforeDownload ({ html2pdf, options, pdfContent }) {
                console.log(`On Before PDF Generation`)
                    html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                        // const totalPages = pdf.internal.getNumberOfPages()
                        // for (let i = 1; i <= totalPages; i++) {
                        // 	pdf.setPage(i)
                        // 	pdf.setFontSize(10)
                        // 	pdf.setTextColor(150)
                        // 	pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                        // } 
                    }).save()
            },
            formatDate(dateString) {
                const [day, month, year] = dateString.split('/');
                const months = {
                '01': 'enero',
                '02': 'febrero',
                '03': 'marzo',
                '04': 'abril',
                '05': 'mayo',
                '06': 'junio',
                '07': 'julio',
                '08': 'agosto',
                '09': 'septiembre',
                '10': 'octubre',
                '11': 'noviembre',
                '12': 'diciembre'
                };
                return `${parseInt(day)} de ${months[month]} del ${year}`;
            },
            getImagePerson(document) {
                _sHelper.getPhotoWorkerBase64(document, this.$fun.getUserID()).then((rsp) => {
                if (rsp.status === 200) {
                    this.image = 'data:image/jpg;base64,' + rsp.data;  // Asignamos la cadena Base64 directamente
                    return this.image;
                } else {
                    console.error("No se pudo cargar la imagen");
                }
                }).catch((error) => {
                console.error("Error al obtener la imagen:", error);
                });

            },
        },
        mounted(){
            console.log('itemSelect', this.itemSelect);
            console.log('item', this.item);
            this.MovemtsRegister = this.item.AgreementCE.AgreementMovemtsCE;
            const AgtIDRegister = this.MovemtsRegister.reduce((acc, current) => {
            const agtID = current.AgtID;
            const amsID = current.AmsID;

            if (!acc[agtID] || amsID > acc[agtID].AmsID) {
                acc[agtID] = current;
            }

            return acc;
            }, {});

            this.contractData = Object.values(AgtIDRegister);
            this.pensionCE = this.item.PensionCE.filter(detail => detail.SecStatus === 1);
            this.HealthCE = this.item.HealthCE.filter(detail => detail.SecStatus === 1);
            this.BankAccountsCE = this.item.BankAccountsCE.filter(detail => detail.SecStatus === 1);
            this.getImagePerson(this.itemSelect.PrsDocumentNumber);

            this.totalpages =  pdf.internal.getNumberOfPages();
        }
    }
</script>